import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "flex justify-center w-full my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_WordDisplay = _resolveComponent("WordDisplay")!
  const _component_Transcription = _resolveComponent("Transcription")!
  const _component_ArabicText = _resolveComponent("ArabicText")!
  const _component_TwoItems = _resolveComponent("TwoItems")!
  const _component_Analysis = _resolveComponent("Analysis")!
  const _component_HowToQuote = _resolveComponent("HowToQuote")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      infoText: _ctx.concordanceWeb.payload?.talmon_info
    }, {
      title: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.$t('concordance.concordance_header')
        }, null, 8, _hoisted_1)
      ]),
      subtitle: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: `${_ctx.$t('global.sura')} ${_ctx.$route.params.sura} ${_ctx.$t('global.verse')}
  ${_ctx.$route.params.verse} ${_ctx.$t('global.word')} ${_ctx.$route.params.word}`
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["infoText"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: { name: 'ConcordanceOverview' } }, {
        default: _withCtx(() => [
          _createVNode(_component_PrimaryButton, { class: "w-72 mb-2" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('concordance.to_overview')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_WordDisplay, {
      arabicVerse: _ctx.arabicVerse,
      transcription: _ctx.transcription,
      class: "max-w-screen-md mx-auto my-6"
    }, null, 8, ["arabicVerse", "transcription"]),
    _createVNode(_component_TwoItems, null, {
      first: _withCtx(() => [
        _createVNode(_component_Transcription, {
          transcriptionInfo: _ctx.concordanceWeb.payload?.transcription_info,
          transcription: _ctx.transcription,
          selectedWord: +_ctx.$route.params.word - 1,
          makeLinks: true
        }, null, 8, ["transcriptionInfo", "transcription", "selectedWord"])
      ]),
      second: _withCtx(() => [
        _createVNode(_component_ArabicText, {
          arabicTextInfo: _ctx.concordanceWeb.payload?.arabic_text_info,
          arabicVerse: _ctx.arabicVerse,
          selectedWord: +_ctx.$route.params.word - 1,
          makeLinks: true
        }, null, 8, ["arabicTextInfo", "arabicVerse", "selectedWord"])
      ]),
      _: 1
    }),
    _createVNode(_component_SectionHeader, {
      title: _ctx.$t('concordance.analysis_heading'),
      infoText: _ctx.concordanceWeb.payload?.analysis_info
    }, null, 8, ["title", "infoText"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.concordanceData.payload?.analyses, (analysis) => {
      return (_openBlock(), _createBlock(_component_Analysis, {
        key: analysis.analysis,
        analysis: analysis
      }, null, 8, ["analysis"]))
    }), 128)),
    (_ctx.isLoaded(_ctx.concordanceWeb))
      ? (_openBlock(), _createBlock(_component_HowToQuote, {
          key: 0,
          citation: _ctx.concordanceWeb.payload.how_to_cite
        }, null, 8, ["citation"]))
      : _createCommentVNode("", true)
  ], 64))
}